const React = require('react');
const { arrayOf, bool, node, number, string, shape } = require('prop-types');
const classNames = require('classnames');
const ActionModal = require('../action-modal');
const ContainerInfo = require('./components/container-info');
const SellerStore = require('./components/seller-store');
const StyledLabel = require('../styled-label');
const componentEnhance = require('../../lib/component-enhance');
const IconFactory = require('../icons/factory-builder')();

const namespace = 'ui-pdp-seller';

const SellerInfo = ({
  className,
  seller,
  sellerInfo,
  subtitles,
  title,
  titleValue,
  url,
  showSellerLogo,
  seller_link,
  brandTitle,
}) => (
  <div className={classNames(namespace, className, { 'ui-pdp-seller__with-logo': !!showSellerLogo })}>
    <SellerStore
      titleValue={
        <>
          {brandTitle && (
            <div className={`${namespace}__brand-title-container`}>
              <StyledLabel
                className={`${namespace}__brand-title mb-4`}
                as="span"
                font_family={brandTitle.font_family}
                font_size={brandTitle.font_size}
                color={brandTitle.color}
                text={componentEnhance.jsx(brandTitle.text || brandTitle, brandTitle.values)}
              />
              {(seller.icon || sellerInfo.official_store_icon) && IconFactory({ id: 'cockade' }, 'ui-pdp-cockade-icon')}
            </div>
          )}
          {title && <span className={`${namespace}__label-sold`}>{title}</span>}
          {seller_link && (
            <ActionModal
              className={`${namespace}__link-trigger non-selectable`}
              modalClassName="ui-pdp-modal-seller"
              deviceType="mobile"
              componentAsLabel={<StyledLabel as="span" {...seller_link.label} />}
              modalTitle={seller_link.label.text}
              modalUrl="#seller-info"
              url={url}
              track={seller_link.track}
              closeModalLabel={seller_link.close_modal_label}
            >
              <ContainerInfo
                {...sellerInfo}
                {...{
                  officialStoreId: seller.officialStoreId,
                  titleValue,
                  brandTitle: sellerInfo.titleLabel,
                  icon: seller.icon || sellerInfo.official_store_icon,
                }}
              />
            </ActionModal>
          )}
        </>
      }
      logo={showSellerLogo ? sellerInfo.logo : ''}
      subtitles={subtitles}
      sellerName={titleValue}
      icon={!brandTitle && (seller.icon || sellerInfo.official_store_icon)}
      iconOnTitle
    />
  </div>
);

SellerInfo.propTypes = {
  className: string,
  seller: shape({
    officialStoreId: number,
  }).isRequired,
  sellerInfo: shape({
    extraInfo: arrayOf(
      shape({
        subtitle: string,
        title: string,
      }),
    ),
    powerSellerStatus: shape({ subtitle: string, title: string }),
    thermometer: shape({
      rank: number,
      info: arrayOf(
        shape({
          icon: shape({ id: string }),
          subtitle: string,
          title: string,
        }),
      ),
      defaultInfo: shape({
        subtitle: shape({
          color: string,
          text: string,
        }),
        title: shape({
          color: string,
          text: string,
        }),
      }),
    }).isRequired,
    subtitle: string,
    logo: string,
    header: string,
  }).isRequired,
  showSellerLogo: bool,
  subtitles: arrayOf(
    shape({
      text: string,
      color: string,
      weight: string,
      size: string,
    }),
  ),
  seller_link: shape({
    label: shape({
      text: string,
      color: string,
    }).isRequired,
    track: shape({ melidata_event: shape({}), analytics_event: shape({}) }),
  }).isRequired,
  title: string,
  titleValue: node.isRequired,
  url: string,
  brandTitle: shape({
    font_family: string,
    color: string,
    font_size: string,
    text: string,
  }),
};

SellerInfo.defaultProps = {
  className: null,
  showSellerLogo: false,
  subtitles: null,
  title: null,
  url: null,
  brandTitle: null,
};

module.exports = SellerInfo;
